<template>
  <div>
    <div v-if="dataPreview">
      <!-- <CardItemPreviewOffering
            :dataOffering="dataPreviewOffering"
            :hidePhoto="true"
          /> -->
      <div v-if="!dataPreview.client_type || dataPreview.client_type.code === 'company'">
        <div class="is-flex is-justify-content-space-between mb-3 px-3">
          <p class="averta-bold label-14">DPP</p>
          <div class="wrap-price is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold label-14">Rp</p>
            <p class="averta-bold label-14">
              {{ formatThousand(dataPreview.preview_price.subtotal) }}
            </p>
          </div>
        </div>
        <div class="is-flex is-justify-content-space-between mb-3 px-3">
          <p class="averta-bold label-14">PPN {{ dataPreview.preview_price.ppn_percent }}</p>
          <div class="wrap-price is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold label-14">Rp</p>
            <p class="averta-bold label-14">{{ formatThousand(dataPreview.preview_price.ppn) }}</p>
          </div>
        </div>
        <div class="is-flex is-justify-content-space-between mb-3 px-3">
          <p class="averta-bold label-14">
            PPh Final 4 Ayat 2 ({{ dataPreview.preview_price.pph_percent }})
          </p>
          <div class="wrap-price is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold label-14">Rp</p>
            <p class="averta-bold label-14">
              ({{ formatThousand(dataPreview.preview_price.pph) }})
            </p>
          </div>
        </div>
        <!-- <div class="divider"></div> -->
      </div>

      <!-- <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p>Survey Charge</p>
            <p class="averta-bold">{{ formatThousand(dataPreview.preview_price.survey_charge) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p>Transport Charge</p>
            <p class="averta-bold">{{ formatThousand(dataPreview.preview_price.transport_charge) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p>Biaya Parkir</p>
            <p class="averta-bold">{{ formatThousand(dataPreview.preview_price.fee_parking) }}</p>
          </div> -->
      <!-- <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p class="averta-black">Total</p>
            <p class="averta-black">{{ formatThousand(dataPreview.preview_price.total) }}</p>
          </div> -->

      <div class="is-flex is-justify-content-space-between mb-3 is-size-5 wrapper-grand p-3">
        <p class="averta-black">Grand Total</p>
        <div
          class="text-rp-grand color-red averta-black is-flex is-align-items-center is-justify-content-space-between"
        >
          <p>Rp</p>
          <p class="averta-black">
            {{ formatThousand(dataPreview.preview_price.total_after_rounding) }}
          </p>
        </div>
      </div>
      <p class="has-text-right mt-2 label-13 pr-3">
        (<span class="averta-bold">Terbilang: </span>
        <span class="is-italic">{{
          spellRupiah(dataPreview.preview_price.total_after_rounding)
        }}</span
        >)
      </p>
    </div>
  </div>
</template>

<script>
import { formatCurrency, Terbilang } from '@/helpers'
import CardItemPreviewOffering from '@/components/card/CardItemPreviewOffering.vue'

export default {
  name: 'TabActualPrice',
  props: {
    dataPreview: {
      type: Object
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CardItemPreviewOffering
  },
  methods: {
    formatThousand (value) {
      return formatCurrency(value, true)
    },
    spellRupiah (value) {
      return Terbilang(value)
    }
  }
}
</script>

<style scoped>
.divider {
  border-top: 2px dashed #e1e1e1;
  margin: 24px 0px;
}

.wrapper-grand {
  background: linear-gradient(90deg, #fffff7 0%, #fff5ea 100%);
  border-radius: 8px;
}

.wrap-price {
  width: 180px;
}

.text-rp-grand {
  width: 180px;
}
</style>
