<template>
  <div class="detail-pekerjaan-wrapper">
    <div class="body-detail">
      <div class="header averta-bold">
        <div class="th first">
          <p class="label-16 ml-2">Detail Pekerjaan</p>
        </div>
        <div class="th sec">
          <p class="label-16 has-text-right">Volume<br />Pekerjaan</p>
        </div>
        <div class="th three">
          <p class="label-16 has-text-centered">Satuan</p>
        </div>
        <div class="th four">
          <p class="label-16 has-text-right">Harga Satuan</p>
        </div>
        <div class="th five">
          <p class="label-16 has-text-right">Total Harga</p>
        </div>
      </div>
      <div class="overflow-item-area">
        <div v-for="(area, index) in dataOffering.item_components" :key="`${index}-offer`">
          <div class="is-flex is-justify-content-space-between mb-4 mt-3">
            <p class="averta-black label-14">{{ area.area_name }}</p>
            <div
              class="averta-black label-14 is-flex is-justify-content-space-between pl-5"
              style="width: 10%"
            >
              <p>Rp</p>
              <p>{{ formatIdr(area.total_price, true) }}</p>
            </div>
          </div>
          <div v-for="(subArea, indexSubArea) in area.components" :key="`${indexSubArea}-subarea`">
            <p class="averta-bold ml-2 mb-3 label-14">{{ subArea.component_name }}</p>
            <div
              class="row-wrapper"
              v-for="(itemWorking, indexItemWorking) in subArea.item_components"
              :key="`${indexItemWorking}-item-working`"
            >
              <div class="td first is-flex">
                <div class="ml-2 c-w-100">
                  <div class="mb-4">
                    <div class="is-flex mb-3">
                      <p class="label-14">{{ itemWorking.numbering }}.</p>
                      <p class="label-14 ml-5">{{ itemWorking.note_item_working }}</p>
                    </div>
                    <div v-if="!hidePhoto" class="foto-wrapper ml-5">
                      <div
                        class="is-flex is-justify-content-space-between is-align-items-center mb-1"
                      >
                        <p class="averta-regular label-14">Foto Survey</p>
                        <p
                          class="averta-bold color-red label-12 is-clickable"
                          @click="openModalImage(itemWorking.images)"
                        >
                          Lihat Foto
                        </p>
                      </div>
                      <div class="is-flex overflow-auto">
                        <div
                          class="foto-img mr-3 mb-1"
                          v-for="(image, indexImage) in itemWorking.images"
                          :key="`${indexImage}-img`"
                        >
                          <img
                            v-if="image.picture_url"
                            class="is-clickable"
                            :src="image.picture_url"
                            alt=""
                            @click="openModalImage(itemWorking.images)"
                          />
                          <img v-else :src="require('@/assets/img/empty-img-2.png')" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="td sec">
                <p class="label-14 has-text-right">{{ itemWorking.volume }}</p>
              </div>
              <div class="td three">
                <p class="label-14 has-text-centered">{{ itemWorking.unit_name }}</p>
              </div>
              <div class="td four is-flex is-justify-content-space-between">
                <p class="label-14">Rp</p>
                <p class="label-14">{{ formatIdr(itemWorking.unit_price, true) }}</p>
              </div>
              <div class="td five is-flex is-justify-content-space-between pl-5">
                <p class="label-14">Rp</p>
                <p class="label-14">{{ formatIdr(itemWorking.total_price, true) }}</p>
              </div>
            </div>
          </div>
          <div class="divider mb-5" v-if="index < dataOffering.item_components.length - 1"></div>
        </div>
      </div>

      <div
        v-if="clientTypeCompany"
      >
        <!-- Doesn't Have a Discount -->
        <div
          v-if="dataOffering.prices && !dataOffering.prices.discount"
          class="footer-wrapper averta-bold"
        >
          <div class="prices-wrapper">
            <div class="prices">
              <p>{{isPriceTenant ? 'Total' : 'DPP'}}</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p>Rp</p>
                <p>{{ formatIdr(dataOffering.prices && dataOffering.prices.subtotal, true) }}</p>
              </div>
            </div>
          </div>
          <div class="prices-wrapper">
            <div class="prices">
              <p>PPN {{ dataOffering.prices && dataOffering.prices.ppn_percent }}%</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p>Rp</p>
                <p>{{ formatIdr(dataOffering.prices && dataOffering.prices.ppn, true) }}</p>
              </div>
            </div>
          </div>
          <div class="prices-wrapper" v-if="dataOffering.prices && dataOffering.prices.pph && !isPriceTenant">
            <div class="prices">
              <p class="white-space-nowrap">
                PPh Final 4 Ayat 2 ({{ dataOffering.prices && dataOffering.prices.pph_percent }}%)
              </p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p>Rp</p>
                <p>({{ formatIdr(dataOffering.prices && dataOffering.prices.pph, true) }})</p>
              </div>
            </div>
          </div>
          <div class="prices-wrapper last">
            <div class="prices">
              <p class="label-18 averta-bold">Grand Total</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p class="label-18 averta-black color-red">Rp</p>
                <p class="label-18 averta-black color-red">
                  {{ formatIdr(dataOffering.prices && dataOffering.prices.total, true) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Discount -->
        <div
          v-else-if="dataOffering.prices && dataOffering.prices.discount"
          class="footer-wrapper averta-bold"
        >
          <div class="prices-wrapper">
            <div class="prices">
              <p>{{isPriceTenant ? 'Total' : 'DPP'}}</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p>Rp</p>
                <p>{{ formatIdr(dataOffering.prices && dataOffering.prices.subtotal, true) }}</p>
              </div>
            </div>
          </div>
          <div class="prices-wrapper">
            <div class="prices">
              <p>Discount</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p>Rp</p>
                <p>({{ formatIdr(dataOffering.prices && dataOffering.prices.discount, true) }})</p>
              </div>
            </div>
          </div>
          <div class="prices-wrapper">
            <div class="prices">
              <p>PPN {{ dataOffering.prices && dataOffering.prices.ppn_percent }}%</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p>Rp</p>
                <p>{{ formatIdr(dataOffering.prices && dataOffering.prices.ppn, true) }}</p>
              </div>
            </div>
          </div>
          <div class="prices-wrapper" v-if="dataOffering.prices && dataOffering.prices.pph && !isPriceTenant">
            <div class="prices">
              <p class="white-space-nowrap">
                PPh Final 4 Ayat 2 ({{ dataOffering.prices && dataOffering.prices.pph_percent }}%)
              </p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p>Rp</p>
                <p>({{ formatIdr(dataOffering.prices && dataOffering.prices.pph, true) }})</p>
              </div>
            </div>
          </div>
          <div class="prices-wrapper last">
            <div class="prices">
              <p class="label-18 averta-bold">Grand Total</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p class="label-18 averta-black color-red">Rp</p>
                <p class="label-18 averta-black color-red">
                  {{ formatIdr(dataOffering.prices && dataOffering.prices.total, true) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="clientTypePersonal">
        <!-- Doesn't Have a Discount -->
        <div
          v-if="dataOffering.prices && !dataOffering.prices.discount"
          class="footer-wrapper averta-bold"
        >
          <div class="prices-wrapper last mt-4">
            <div class="prices">
              <p class="label-18 averta-bold">Grand Total</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p class="label-18 averta-black color-red">Rp</p>
                <p class="label-18 averta-black color-red">
                  {{ formatIdr(dataOffering.prices && dataOffering.prices.total, true) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Discount -->
        <div
          v-else-if="dataOffering.prices && dataOffering.prices.discount"
          class="footer-wrapper averta-bold"
        >
          <div class="prices-wrapper">
            <div class="prices">
              <p>Total</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p>Rp</p>
                <p>{{ formatIdr(dataOffering.prices && dataOffering.prices.subtotal, true) }}</p>
              </div>
            </div>
          </div>
          <div class="prices-wrapper">
            <div class="prices">
              <p>Discount</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p>Rp</p>
                <p>{{ formatIdr(dataOffering.prices && dataOffering.prices.discount, true) }}</p>
              </div>
            </div>
          </div>
          <div class="prices-wrapper last">
            <div class="prices">
              <p class="label-18 averta-bold">Grand Total</p>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
                style="width: 160px"
              >
                <p class="label-18 averta-black color-red">Rp</p>
                <p class="label-18 averta-black color-red">
                  {{ formatIdr(dataOffering.prices && dataOffering.prices.total, true) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap-terbilang label-16 has-text-right mt-3">
        <p>
          (<span class="averta-bold mr-1">Terbilang:</span>
          <span class="averta-regular-italic">{{ formatSpellOut(dataOffering.prices && dataOffering.prices.total) }}</span
          >)
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency, Terbilang } from '@/helpers'

export default {
  name: 'CardItemPreviewOffering',
  props: {
    dataOffering: {
      type: Object,
      default: () => {}
    },
    hidePhoto: {
      type: Boolean,
      default: false
    },
    selectedTipeOffering: String
  },
  computed: {
    isPriceTenant () {
      return this.selectedTipeOffering === 'price-tenant'
    },
    clientTypeCompany () {
      return this.dataOffering.client_type_code === 'company' ||
          this.dataOffering.client_type_code === null ||
          !this.dataOffering.client_type_code
    },
    clientTypePersonal () {
      return this.dataOffering.client_type_code === 'personal'
    }
  },
  methods: {
    formatIdr (value, hideCurrency) {
      return formatCurrency(value, hideCurrency)
    },
    formatSpellOut (value) {
      return Terbilang(value)
    },
    openModalImage (data) {
      this.$emit('openModalImage', data)
    }
  }
}
</script>

<style scoped>
.divider {
  border-top: 1px solid #E1E1E1;
}

.detail-pekerjaan-wrapper {
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  padding: 20px;
  overflow: auto;
  margin-bottom: 2em;
}

.body-detail {
  width: 100%;
}

.header {
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 5px;
}

.row-wrapper {
  flex-shrink: 0;
}

.header .th,
.td {
  flex-shrink: 0;
  margin-bottom: 20px;
}

.th.numbering,
.td.numbering {
  width: 5%;
}

.th.first,
.td.first {
  width: 61%;
}

.th.sec,
.td.sec {
  width: 10%;
}

.th.three,
.td.three {
  width: 10%;
}

.th.four,
.td.four {
  width: 9%;
}

.th.five,
.td.five {
  width: 10%;
}

.row-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.foto-img {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
}

.foto-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.foto-wrapper {
  border-radius: 16px;
  background: #fafafa;
  padding: 12px;
  width: 50%;
}

.prices-wrapper,
.prices {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.prices-wrapper {
  padding: 12px;
  padding-right: 0;
}

.prices-wrapper.last {
  background: linear-gradient(90deg, #fffff7 0%, #fff5ea 100%);
  border-radius: 8px;
  padding-right: 12px;
  margin-right: -12px;
}

.prices {
  width: 24em;
  justify-content: space-between;
}

.footer-wrapper {
  border-top: 1px solid #e1e1e1;
}

.overflow-item-area {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 0.8em;
  margin-right: -0.8em;
}
</style>
